// src/Dashboard.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import './Dashboard.css';

const Dashboard = () => {
  const navigate = useNavigate();

  const handleUploadCsv = () => {
    navigate('/upload-csv');
  };

  const handleSendMail = () => {
    navigate('/send-mail');
  };

  const handleResetAll = async () => {
    try {
      const response = await fetch('https://mailer.lifeworthhmo.com/api/Upload', {
        method: 'PUT',
      });

      if (response.ok) {
        alert('All data has been reset successfully!');
      } else {
        alert('Failed to reset data.');
      }
    } catch (error) {
      alert('An error occurred while resetting data.');
    }
  };

  const handleViewList = () => {
    navigate('/view-list'); // Navigate to ViewList page
  };

  return (
    <div className="dashboard">
      <Navbar />
      <div className="dashboard-cards">
        <div className="card" onClick={handleUploadCsv}>
          <h3>Upload CSV</h3>
        </div>
        <div className="card" onClick={handleSendMail}>
          <h3>Send Mail</h3>
        </div>
        <div className="card" onClick={handleResetAll}>
          <h3>Reset All</h3>
        </div>
        <div className="card" onClick={handleViewList}>
          <h3>View List</h3>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
