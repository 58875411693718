import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Logic for logging out the user (e.g., clearing session data)
    alert('You have been logged out.');
    navigate('/login');
  };

  return (
    <nav className="navbar">
      <h2 className="navbar-title">Dashboard</h2>
      <button className="logout-button" onClick={handleLogout}>Logout</button>
    </nav>
  );
};

export default Navbar;
