import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SendMail.css';
import Navbar from './Navbar';

const SendMail = () => {
  const [subject, setSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleBodyChange = (event) => {
    setEmailBody(event.target.value);
  };

  const handleFileChange = (event) => {
    setAttachment(event.target.files[0]);
  };

  const handleSendMail = async (event) => {
    event.preventDefault();
    if (!subject) {
      setMessage('Please enter the email subject.');
      return;
    }
    if (!emailBody) {
      setMessage('Please enter the email body.');
      return;
    }

    setSending(true);
    setMessage('');

    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('body', emailBody);
    if (attachment) {
      formData.append('attachment', attachment);
    }

    try {
      const response = await fetch('https://mailer.lifeworthhmo.com/api/Mail/SendBulkEmail', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setMessage('Email sent successfully!');
      } else {
        setMessage('Failed to send email.');
      }
    } catch (error) {
      setMessage('An error occurred while sending the email.');
    } finally {
      setSending(false);
    }
  };

  return (
    <div className="upload-container">
      <Navbar />
      <div className="send-mail-page">
        <div className="send-mail-card">
          <button
            className="back-button"
            onClick={() => navigate('/dashboard')}
          >
            Back to Dashboard
          </button>
          <h2>Send Mail</h2>
          <form onSubmit={handleSendMail} className="send-mail-form">
            <input
              type="text"
              placeholder="Enter email subject"
              value={subject}
              onChange={handleSubjectChange}
              className="subject-input"
            />
            <textarea
              placeholder="Enter email body"
              value={emailBody}
              onChange={handleBodyChange}
              className="email-body-input"
            />
            <input
              type="file"
              onChange={handleFileChange}
              className="file-input"
            />
            <button
              type="submit"
              className="send-mail-button"
              disabled={sending}
            >
              {sending ? 'Sending...' : 'Send Mail'}
            </button>
          </form>
          {message && <p className="send-mail-message">{message}</p>}
        </div>
      </div>
    </div>
  );
};

export default SendMail;
