import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Navbar from './Navbar';
import './UploadCsv.css';

const UploadCsv = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate(); // Initialize navigate

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    if (!file) {
      setMessage('Please select a CSV file to upload.');
      return;
    }

    setUploading(true);
    setMessage('');

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('https://mailer.lifeworthhmo.com/api/Upload/upload', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setMessage('File uploaded successfully!');
      } else {
        setMessage('Failed to upload file.');
      }
    } catch (error) {
      setMessage('An error occurred during file upload.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="upload-container">
      <Navbar />
      <div className="send-mail-page">
        
        <div className="send-mail-card">
      <button
          className="back-button"
          onClick={() => navigate('/dashboard')} // Navigate to Dashboard
        >
          Back to Dashboard
        </button>
      <h2>Upload CSV</h2>
      <form onSubmit={handleUpload} className="upload-form">
        <input
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          className="file-input"
        />
        <button
          type="submit"
          className="upload-button"
          disabled={uploading}
        >
          {uploading ? 'Uploading...' : 'Upload'}
        </button>
      </form>
      {message && <p className="upload-message">{message}</p>}
    </div>
    </div>
    </div>
  );
};

export default UploadCsv;
