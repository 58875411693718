// src/ViewList.jsx
import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import './ViewList.css';
import { useNavigate } from 'react-router-dom';

const ViewList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://mailer.lifeworthhmo.com/api/upload/Users'); // Update the API endpoint as needed
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div className=" upload-container">
      <Navbar />
      <div className="send-mail-page">
      <div className="send-mail-card">
      <button
        className="back-button"
        onClick={() => navigate('/dashboard')}
      >
        Back to Dashboard
      </button>
      <h2>User List</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="user-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
    </div>
    </div>
  );
};

export default ViewList;
